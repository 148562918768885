<template>
  <div>
    <v-row class="d-flex justify-center my-9">
      <v-col cols="12" sm="11" md="9" lg="9">
        <h1 class="text-center font-weight-black" style="font-size: 60px; color: #1c244b;">{{$t('plans.titles.mainTitle2')}}</h1>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center">
      <v-col cols="12" sm="11" md="9" lg="6">
        <v-select
          v-model="nationalType"
          item-text="text"
          item-value="value"
          :items="plansTypes"
          outlined
          item-color="teal"
          color="#2ecab5"
          height="30"
          class="mx-5"
          @change="onChangeNationalType"
        >
        <template v-slot:item="{ item }">
            <div class="d-flex align-center">
              <v-img v-if="item.flag" :src="item.flag" max-height="20" max-width="30" class="mr-2"></v-img>
              <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
              <span>{{ $t(item.text.langKey) }}</span>
            </div>
          </template>
          <template v-slot:selection="{ item }">
            <div class="d-flex align-center">
              <v-img v-if="item.flag" :src="item.flag" max-height="20" max-width="30" class="mr-2"></v-img>
              <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
              <span>{{ $t(item.text.langKey) }}</span>
            </div>
          </template>
        </v-select>
      </v-col>
    </v-row>

    <v-row class="justify-center">
      <v-col cols="12" sm="11" md="9" lg="9">
        <v-btn-toggle v-model="selectedPlanDuration" @change="getFilteredPlans" mandatory class="d-flex stretch mx-5">
          <v-btn
            value='monthly'
            class="plans-button flex-grow-1"
            :class="{ 'selected': selectedPlanDuration === 'monthly' }"
          >
            {{ $t('plans.buttons.radioButton1') }}
          </v-btn>
          <v-btn
            value='yearly'
            v-if="nationalType === 'co'" class="plans-button flex-grow-1"
            :class="{ 'selected': selectedPlanDuration === 'yearly' }"
          >
            {{ $t('plans.buttons.radioButton2') }}
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col cols="12" sm="12" md="10" lg="11">
        <v-simple-table>
          <template>
            <thead>
              <tr>
                <th v-if="selectedPlanDuration === 'monthly'" class="text-center text-body-1 text-md-h5 text-lg-h5 black--text font-weight-bold">
                  {{ $t('plans.plansFeatures.priceTitles.titleMonthly') }}
                </th>
                <th v-else class="text-center text-body-1 text-md-h5 text-lg-h5 black--text font-weight-bold">
                  {{ $t('plans.plansFeatures.priceTitles.titleAnual') }}
                </th>
                <th class="text-center mt-10 text-body-1 text-md-h5 text-lg-h5 black--text pt-5 "
                v-for="(plan, index) in getFilteredPlans()"
                :key="index"
                >
                <v-col class="py-0 pr-5 pr-sm-8 pr-md-5 px-lg-13" style=" position: relative;">
                    <template v-if="nationalType === 'co'">
                    <div v-if="plan.is_most_seller_national" class="most-seller-badge text-body-2 text-md-body-2 text-lg-body-1 font-weight-black">
                      {{$t('plans.mostSeller')}}
                    </div>
                    </template>
                    <template v-else>
                    <div v-if="plan.is_most_seller_international" class="most-seller-badge text-body-2 text-md-body-2 text-lg-body-1 font-weight-black">
                      {{$t('plans.mostSeller')}}
                    </div>
                    </template>
                    <h1 class="text-body-1 text-md-h5 text-lg-h5 font-weight-bold">
                      {{ $t(plan.name.langKey) }}
                    </h1>
                    <p class="text-h4 text-md-h3 text-lg-h3 font-weight-bold" style="white-space: nowrap">
                      {{ calculatePrice(plan) | currencyFormat }}
                    </p>
                    <p class="text-body-1 text-md-h5 text-lg-h5">
                      {{ getCurrencyAndDurationType() }}
                    </p>
                  </v-col>
                  </th>
                </tr>
            </thead>
            <tbody>
              <tr
                v-for="allfeature in getFeatures()"
                :key="allfeature.id"
              >
                <td class="text-body-1 text-md-h5 text-lg-h6 mx-16">{{ $t(allfeature.name.langKey) }}</td>
                <template v-if="allfeature.widthRemindersVia">
                  <td class="text-center text-body-1 text-md-h6 text-lg-h6" v-for="(plan, index) in getFilteredPlans()" :key="index">
                    {{ $t(plan.availableFeatures.find(aFeateure => aFeateure.id === allfeature.id).viaLangKey) }}
                  </td>
                </template>
                <template v-else-if="allfeature.withComment">
                  <td class="text-center pa-0" v-for="(plan, index) in getFilteredPlans()" :key="index">
                    <span class="text-h4" v-if="plan.availableFeatures.find(aFeateure => aFeateure.id === allfeature.id).comment">*</span>
                    <v-icon color="black" v-else>mdi-checkbox-marked-outline</v-icon>
                  </td>
                </template>
                <template v-else-if="allfeature.withlimitValue">
                  <td class="text-center text-body-1 text-md-h6 text-lg-h6" v-for="(plan, index) in getFilteredPlans()" :key="index">
                    {{ $t(plan.availableFeatures.find(aFeateure => aFeateure.id === allfeature.id).limitValueLangKey) }}
                  </td>
                </template>
                <template v-else>
                  <td v-for="(plan, index) in getFilteredPlans()" :key="index">
                    <div class="text-center" v-if="plan.availableFeatures.findIndex(aFeateure => aFeateure.id === allfeature.id) >= 0">
                      <v-icon color="black">mdi-checkbox-marked-outline</v-icon>
                    </div>
                    <div v-else>
                    </div>
                  </td>
                </template>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row  class="d-flex justify-center">
      <v-col cols="11">
        <v-divider :thickness="16"></v-divider>
      </v-col>
    </v-row>
    <hr class="my-16">
    <v-row class=" justify-center">
      <v-col cols="12" sm="11" md="9" lg="9">
        <h1 class="text-center my-5 complement-main-title text-h3 font-weight-black"> {{ $t('plans.titles.complementsTitle') }}</h1>
        <p class="text-center my-7 complement-main-subtitle text-h5">{{ $t('plans.titles.complementsSubTitle') }}</p>
      </v-col>
    </v-row>
    <!--v-row>
      <v-col>
        <h1 class="text-center title-whatsapp text-h2 font-weight-black">
          <v-icon class="mx-3 text-h2" color="#1c244b">mdi-whatsapp</v-icon>
          {{ $t('plans.titles.whatsappTitle') }}
        </h1>
        <h1 v-if="nationalType === 'international'" class="text-center title-whatsapp text-h5 mt-2">
          {{ $t('plans.complements.whatsappGeneralDescriptionInternational') }}
        </h1>
        <h1 v-else class="text-center title-whatsapp text-h5 mt-2">
          {{ $t('plans.complements.whatsappGeneralDescription') }}
        </h1>
      </v-col>
    </v-row>
    <v-row class="mt-9 d-flex justify-center" no-gutters>
      <v-col
        v-for="(planComplement, indexplan) in getFilteredComplementsWhatsapp()"
        :key="indexplan"
        cols="12"
        sm="5"
        md="4"
        lg="3"
        class="align-center justify-center my-7 pa-0 mx-1 "
      >
        <v-row class="justify-center">
          <v-col cols="8" class="rounded-xxl col-with-border elevation-23">
            <v-row >
              <v-col cols="12" class="py-0">
                <h4 class="px-0 mt-6 text-center font-weight-black complement-title-card text-h4">{{ $t(planComplement.name.langKey) }}</h4>
                <v-divider class="mt-5" :thickness="4"></v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="py-0">
                <p class="text-center text-h5">{{ $t(planComplement.description.langKey) }}</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="py-0">
                <p class="text-center mb-5 text-break font-weight-black complement-price text-h4">
                  <span class="font-weight-light">USD </span>{{ calculatePriceComplement(planComplement)| currencyFormat }}</p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center my-5">
      <v-container>
        <v-col cols="12">
          <h1 class="text-center text-h6 text-sm-h6 text-md-h5 text-lg-h4 font-weight-bold">
            {{ $t('plans.complements.whatsappDisclamerTitle') }}
          </h1>
          <h1 class="text-center text-subtitle-1 text-sm-subtitle-1 text-md-h6 text-lg-h5">
            {{ $t('plans.complements.whatsappDisclamerText') }}
          </h1>
        </v-col>
      </v-container>
    </v-row-->
    <hr class="my-16" v-if="nationalType === 'co'">
    <v-row v-if="nationalType === 'co'">
      <v-col cols="12">
        <h1 class="text-center title-whatsapp text-h2 font-weight-black">
          <v-icon class="mx-3 text-h2" color="#1c244b">mdi-message-processing</v-icon>
          {{ $t('plans.titles.smsTitle') }}
        </h1>
      </v-col>
    </v-row>
    <v-row class="mt-9 d-flex justify-center" v-if="nationalType === 'co'" no-gutters>
      <v-col
        v-for="(planComplement, indexplan) in getFilteredComplementsSms()"
        :key="indexplan"
        cols="12"
        sm="6"
        md="4"
        lg="3"
        class="align-center justify-center my-7"
      >
        <v-row class="justify-center">
          <v-col cols="7" class="rounded-xxl col-with-border elevation-23">
            <v-row>
              <v-col cols="12" class=" py-0">
                <h4 class="px-0 mt-6 text-center font-weight-black complement-title-card text-h4">{{ $t(planComplement.name.langKey) }}</h4>
                <v-divider class="mt-5" :thickness="4"></v-divider>
              </v-col>
            </v-row>
            <v-row class="justify-center">
              <v-col cols="12" class=" py-0">
                <p class="text-center text-h5 px-0">{{ $t(planComplement.description.langKey) }}</p>
              </v-col>
            </v-row>
            <v-row class="justify-center">
              <v-col cols="12" class=" py-0">
                <p class="text-center mb-5 text-break complement-price font-weight-black text-h4">
                  {{ calculatePriceComplement (planComplement) | currencyFormat }}
                  <span v-if="nationalType === 'international'" class="font-weight-light text-h6">Usd</span>
                  <span v-else  class="font-weight-light text-body-2">+ IVA</span>
                </p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center" v-if="nationalType === 'co'">
      <v-container>
        <v-col cols="12">
          <h1 class="text-center text-h6 text-sm-h6 text-md-h5 text-lg-h4 font-weight-bold">
            {{ $t('plans.complements.smsDisclamerTitle') }}
          </h1>
          <h1 class="text-center text-subtitle-1 text-sm-subtitle-1 text-md-h6 text-lg-h5">
            {{ $t('plans.complements.smsDisclamerText') }}
          </h1>
        </v-col>
      </v-container>
    </v-row>
  </div>
</template>

<script>

export default {
  data () {
    return {
      selectedPlanDuration: 'monthly',
      nationalType: 'co',
      allFeatures: [
        {
          id: 'feature-system-billing',
          name: { langKey: 'plans.plansFeatures.allFeatures.nameBox' }
        },
        {
          id: 'feature-reports',
          name: { langKey: 'plans.plansFeatures.allFeatures.nameReports' }
        },
        {
          id: 'feature-commissions',
          name: { langKey: 'plans.plansFeatures.allFeatures.nameCommission' }
        },
        {
          id: 'feature-management',
          name: { langKey: 'plans.plansFeatures.allFeatures.nameManagement' }
        },
        {
          id: 'feature-inventory',
          name: { langKey: 'plans.plansFeatures.allFeatures.nameInventory' }
        },
        {
          id: 'feature-bookings',
          name: { langKey: 'plans.plansFeatures.allFeatures.nameBookings' }
        },
        {
          id: 'feature-staff',
          name: { langKey: 'plans.plansFeatures.allFeatures.nameStaff' },
          withlimitValue: true
        },
        {
          id: 'feature-support',
          name: { langKey: 'plans.plansFeatures.allFeatures.nameSupport' }
        },
        {
          id: 'feature-loyalty',
          name: { langKey: 'plans.plansFeatures.allFeatures.nameLoyalty' }
        },
        {
          id: 'feature-satisfaction',
          name: { langKey: 'plans.plansFeatures.allFeatures.nameSatisfaction' }
        },
        {
          id: 'feature-reminders',
          name: { langKey: 'plans.plansFeatures.allFeatures.nameReminder' },
          widthRemindersVia: true
        },
        {
          id: 'feature-digital-orders',
          name: { langKey: 'plans.plansFeatures.allFeatures.nameDigitalOrders' }
        },
        {
          id: 'feature-billing-pos',
          name: { langKey: 'plans.plansFeatures.allFeatures.nameBillingPos' },
          withComment: true,
          countries: 'co'
        }
      ],
      plans: [
        {
          name: { langKey: ('plans.titles.planTitleEntrepreneur') },
          can_monthly: true,
          can_yearly: false,
          countries: ['international'],
          is_most_seller_national: false,
          is_most_seller_international: false,

          price_monthly: 0,
          price_yearly: 0,
          availableFeatures: [
            { id: 'feature-system-billing' },
            { id: 'feature-reports' },
            { id: 'feature-commissions' },
            { id: 'feature-management' },
            // { id: 'feature-inventory' },
            { id: 'feature-bookings' },
            { id: 'feature-staff', limitValueLangKey: 'plans.plansFeatures.sideFeatures.staffUntil5' },
            { id: 'feature-support' },
            { id: 'feature-reminders', viaLangKey: 'plans.plansFeatures.sideFeatures.reminderEmail' },
            { id: 'feature-billing-pos', comment: '*' }
          ],

          price_monthly_international: 20
        },
        {
          name: { langKey: ('plans.titles.planTitle3') },
          can_monthly: true,
          can_yearly: true,
          countries: ['co', 'international'],
          is_most_seller_national: false,
          is_most_seller_international: true,

          price_monthly: 120900,
          price_yearly: 1200900,
          availableFeatures: [
            { id: 'feature-system-billing' },
            { id: 'feature-reports' },
            { id: 'feature-commissions' },
            { id: 'feature-management' },
            { id: 'feature-inventory' },
            { id: 'feature-bookings' },
            { id: 'feature-staff', limitValueLangKey: 'plans.plansFeatures.sideFeatures.staffUntil10' },
            { id: 'feature-support' },
            { id: 'feature-reminders', viaLangKey: 'plans.plansFeatures.sideFeatures.reminderEmail' },
            { id: 'feature-billing-pos', comment: '*' }
          ],

          price_monthly_international: 40
        },
        {
          name: { langKey: ('plans.titles.planTitle2') },
          can_monthly: true,
          can_yearly: true,
          countries: ['co', 'international'],
          is_most_seller_national: true,
          is_most_seller_international: false,

          price_monthly: 180900,
          price_yearly: 1810900,
          availableFeatures: [
            { id: 'feature-system-billing' },
            { id: 'feature-reports' },
            { id: 'feature-commissions' },
            { id: 'feature-management' },
            { id: 'feature-inventory' },
            { id: 'feature-bookings' },
            { id: 'feature-staff', limitValueLangKey: 'plans.plansFeatures.sideFeatures.staffUntil30' },
            { id: 'feature-support' },
            { id: 'feature-loyalty' },
            { id: 'feature-reminders', viaLangKey: 'plans.plansFeatures.sideFeatures.reminderEmailSms' },
            { id: 'feature-digital-orders' },
            { id: 'feature-billing-pos', comment: '*' }
          ],

          price_monthly_international: 60,
          price_yearly_international: 0
        },
        {
          name: { langKey: ('plans.titles.planTitle4') },
          can_monthly: true,
          can_yearly: true,
          countries: ['co'],
          is_most_seller_national: false,
          is_most_seller_international: false,

          price_monthly: 300900,
          price_yearly: 3000900,
          availableFeatures: [
            { id: 'feature-system-billing' },
            { id: 'feature-reports' },
            { id: 'feature-commissions' },
            { id: 'feature-management' },
            { id: 'feature-inventory' },
            { id: 'feature-bookings' },
            { id: 'feature-staff', limitValueLangKey: 'plans.plansFeatures.sideFeatures.staffUnlimited' },
            { id: 'feature-support' },
            { id: 'feature-loyalty' },
            { id: 'feature-satisfaction' },
            { id: 'feature-reminders', viaLangKey: 'plans.plansFeatures.sideFeatures.reminderApiSmsEmail' },
            { id: 'feature-digital-orders' },
            { id: 'feature-billing-pos' }
          ],

          price_monthly_international: '81.81'
        }
      ],
      plansTypes: [
        {
          text: { langKey: 'plans.buttons.select1' },
          value: 'co',
          flag: require('@/assets/sass/co.png')
        },
        {
          text: { langKey: 'plans.buttons.select2' },
          value: 'international',
          icon: 'mdi-earth'
        }],
      planComplements: [
        /*
        {
          name: { langKey: ('plans.complements.whatsappTitles.title1000') },
          description: { langKey: ('plans.complements.whatsappDescription.description1000') },
          can_monthly: true,
          can_yearly: true,
          is_whatsapp: true,
          is_sms: false,
          countries: ['co', 'international'],
          price_monthly: 20,
          price_yearly: 20,
          price_monthly_international: 20
        },
        {
          name: { langKey: ('plans.complements.whatsappTitles.title2000') },
          description: { langKey: ('plans.complements.whatsappDescription.description2000') },
          can_monthly: true,
          can_yearly: true,
          is_whatsapp: true,
          is_sms: false,
          countries: ['co', 'international'],
          price_monthly: 40,
          price_yearly: 40,
          price_monthly_international: 40
        },
        {
          name: { langKey: ('plans.complements.whatsappTitles.title3000') },
          description: { langKey: ('plans.complements.whatsappDescription.description3000') },
          can_monthly: true,
          can_yearly: true,
          is_whatsapp: true,
          is_sms: false,
          countries: ['co', 'international'],
          price_monthly: 60,
          price_yearly: 60,
          price_monthly_international: 60
        },
        {
          name: { langKey: ('plans.complements.whatsappTitles.title5000') },
          description: { langKey: ('plans.complements.whatsappDescription.description5000') },
          can_monthly: true,
          can_yearly: true,
          is_whatsapp: true,
          is_sms: false,
          countries: ['co', 'international'],
          price_monthly: 100,
          price_yearly: 100,
          price_monthly_international: 100
        },
        {
          name: { langKey: ('plans.complements.whatsappTitles.title10000') },
          description: { langKey: ('plans.complements.whatsappDescription.description10000') },
          can_monthly: true,
          can_yearly: true,
          is_whatsapp: true,
          is_sms: false,
          countries: ['co', 'international'],
          price_monthly: 200,
          price_yearly: 200,
          price_monthly_international: 200
        },
        */
        {
          name: { langKey: ('plans.complements.smsTitles.title500') },
          description: { langKey: ('plans.complements.smsDescription.description500') },
          can_monthly: true,
          can_yearly: true,
          is_whatsapp: false,
          is_sms: true,
          countries: ['co'],
          price_monthly: 18900,
          price_yearly: 18900,
          price_monthly_international: ''
        },
        {
          name: { langKey: ('plans.complements.smsTitles.title1000') },
          description: { langKey: ('plans.complements.smsDescription.description1000') },
          can_monthly: true,
          can_yearly: true,
          is_whatsapp: false,
          is_sms: true,
          countries: ['co'],
          price_monthly: 25900,
          price_yearly: 25900,
          price_monthly_international: ''
        },
        {
          name: { langKey: ('plans.complements.smsTitles.title3000') },
          description: { langKey: ('plans.complements.smsDescription.description3000') },
          can_monthly: true,
          can_yearly: true,
          is_whatsapp: false,
          is_sms: true,
          countries: ['co'],
          price_monthly: 75900,
          price_yearly: 75900,
          price_monthly_international: ''
        },
        {
          name: { langKey: ('plans.complements.smsTitles.title5000') },
          description: { langKey: ('plans.complements.smsDescription.description5000') },
          can_monthly: true,
          can_yearly: true,
          is_whatsapp: false,
          is_sms: true,
          countries: ['co'],
          price_monthly: 100900,
          price_yearly: 100900,
          price_monthly_international: ''
        },
        {
          name: { langKey: ('plans.complements.smsTitles.title10000') },
          description: { langKey: ('plans.complements.smsDescription.description10000') },
          can_monthly: true,
          can_yearly: true,
          is_whatsapp: false,
          is_sms: true,
          countries: ['co'],
          price_monthly: 180900,
          price_yearly: 180900,
          price_monthly_international: ''
        },
        {
          name: { langKey: ('plans.complements.smsTitles.title20000') },
          description: { langKey: ('plans.complements.smsDescription.description20000') },
          can_monthly: true,
          can_yearly: true,
          is_whatsapp: false,
          is_sms: true,
          countries: ['co'],
          price_monthly: 300900,
          price_yearly: 300900,
          price_monthly_international: ''
        }
      ]
    }
  },
  methods: {
    getFeatures () {
      return this.allFeatures.filter(item => {
        if (item.countries) {
          return item.countries === this.nationalType
        }
        return true
      })
    },
    onChangeNationalType (value) {
      if (value === 'international') {
        this.selectedPlanDuration = 'monthly'
      }
    },
    calculatePrice (plan) {
      if (this.selectedPlanDuration === 'monthly') {
        if (this.nationalType === 'international') {
          return plan.price_monthly_international
        } else {
          return plan.price_monthly
        }
      } else if (this.selectedPlanDuration === 'yearly') {
        if (this.nationalType === 'international') {
          return ''
        } else {
          return plan.price_yearly
        }
      }
    },
    getCurrencyAndDurationType () {
      if (this.nationalType === 'international') {
        return this.$t('plans.currency.usd')
      } else {
        return this.selectedPlanDuration === 'monthly' ? this.$t('plans.currency.monthlyNational') : this.$t('plans.currency.anualNational')
      }
    },
    calculatePriceComplement (planComplement) {
      if (this.selectedPlanDuration === 'monthly') {
        if (this.nationalType === 'international') {
          return planComplement.price_monthly_international
        } else {
          return planComplement.price_monthly
        }
      } else if (this.selectedPlanDuration === 'yearly') {
        if (this.nationalType === 'international') {
          return ''
        } else {
          return planComplement.price_yearly
        }
      }
    },
    getFilteredPlans () {
      return this.plans.filter(plan => {
        if (this.selectedPlanDuration === 'monthly') {
          return plan.can_monthly && (plan.countries === null || plan.countries.includes(this.nationalType))
        } else if (this.selectedPlanDuration === 'yearly') {
          return plan.can_yearly && (plan.countries === null || plan.countries.includes(this.nationalType))
        }
      })
    },
    getFilteredComplementsWhatsapp () {
      return this.planComplements.filter(complement => {
        if (complement.is_whatsapp) {
          if (this.selectedPlanDuration === 'monthly') {
            return complement.can_monthly && (complement.countries === null || complement.countries.includes(this.nationalType))
          } else if (this.selectedPlanDuration === 'yearly') {
            return complement.can_yearly && (complement.countries === null || complement.countries.includes(this.nationalType))
          }
        }
        return false
      })
    },
    getFilteredComplementsSms () {
      return this.planComplements.filter(complement => {
        if (complement.is_sms) {
          if (this.selectedPlanDuration === 'monthly') {
            return complement.can_monthly && (complement.countries === null || complement.countries.includes(this.nationalType))
          } else if (this.selectedPlanDuration === 'yearly') {
            return complement.can_yearly && (complement.countries === null || complement.countries.includes(this.nationalType))
          }
        }
        return false
      })
    },
    openWhatsapp () {
      const cellphone = '573187601559'
      const url = `https://api.whatsapp.com/send?phone=${cellphone}&text=Hola+Lizto+Software`
      window.open(url)
    }
  }
}
</script>
<style scoped>
.most-seller-badge {
  background-color: #4caf50;
  color: white;
  border-radius: 10px;
  padding: 0px 6px;
  position: absolute;
  top: 10px;
  right: -29px;
  transform: rotate(45deg);
}
.col-with-border {
  border: 1px solid #ccc; /* Establece el grosor y el color del borde */
}
.plans-button:active {
  color: rgb(0, 60, 255);
}
.v-btn::before {
  background-color: transparent;
}
.selected{
  background-color: #2ecab5 !important ;
  color: white !important;
}
.complement-main-title{
  color:#1c244b;
}
.complement-main-subtitle{
  color: #1c244b;
}
.title-whatsapp{
  color: #1c244b;
}
.complement-title-card{
  color: #1c244b;
}
.complement-price{
  font-size: 40px;
}
</style>
