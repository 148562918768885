<template>
    <v-container fluid class="my-16 py-16">
        <Plans />
    </v-container>
</template>
<script>
import Plans from '@/views/components/Plans.vue'

export default {
  data () {
    return {}
  },
  methods: {
    setDocumentTitle () {
      document.title = this.$t('base.documentTitle')
    }
  },
  components: {
    Plans
  },
  watch: {
    '$root.$i18n.locale' () { this.setDocumentTitle() }
  },
  mounted () {
    let defLocale = 'es'

    if (this.$route.query.l) {
      if (['es', 'en'].indexOf(this.$route.query.l) >= 0) {
        defLocale = this.$route.query.l
      }
    }

    this.$root.$i18n.locale = defLocale
  },
  created () { this.setDocumentTitle() }
}
</script>
